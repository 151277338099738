/**
 * external libs
 */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
/**
 * components
 */
import { ScrollParallax, MoveParallax } from '../../animate'
import { GoldText, Ico, H2, H3, Text } from '../../ui'
/**
 * types
 */
import { ProductionType } from "../../../types";
/**
 * styles
 */
import styles from './production.module.scss'

type Props = {
    containerRef: React.RefObject<HTMLDivElement>
}

const productionItems: ProductionType[] = [
    {
        id: "1",
        title: "alcohol",
        img: "/prod1.webp",
        number: "01",
        describe: "We use premium grain alcohol produced at our own plants from raw materials of highest quality",
    },
    {
        id: "2",
        title: "water",
        img: "/prod2.webp",
        number: "02",
        describe: "Water is extracted from our own well located on the distillery territory at a depth of more than 220 meters. It is gently filtered without thermal treatment and softened through additional ion filtration to give it a special smoothness",
    },
    {
        id: "3",
        title: "blending",
        img: "/prod3.webp",
        number: "03",
        describe: "Alcohol is mixed with water under high pressure and is cooled to minus 15 degrees for additional segmentation of possible impurities",
    },
    {
        id: "4",
        title: "filtration",
        img: "/prod4.webp",
        number: "04",
        describe: "Schmidt vodka is filtered without additional pressure through a charcoal filter made from maple, coconut palm and birch wood. Then vodka undergoes a unique filtration process through natural white pearls giving vodka its unique velvety smoothness",
    },
]

const Production: React.FC<Props> = ({ containerRef }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.production} >
            <div className={`container container_long ${styles.production__content}`}>
                <div className={styles.production__titleWrapper}>
                    <div className={styles.production__title}>
                        <div className={styles.production__scroll}>
                            <GoldText>{t("let's explore")}</GoldText>

                            <Ico
                                ico={<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.8311 12L9.7644 8.93334L10.4669 8.23083L14.2361 12L10.4669 15.7692L9.7644 15.0667L12.8311 12Z" />
                                </svg>}
                                filled={true}
                            />
                        </div>

                        <H2>{t("production")}</H2>
                    </div>
                </div>

                <div className={styles.production__firstPictureWrapper}>
                    <ScrollParallax scrollContainerRef={containerRef} coefficient={4}>
                        <img src="/production.webp" alt={t("production")} className={styles.production__firstPic} />
                    </ScrollParallax>
                </div>

                <div className={styles.production__itemsWrapper}>
                    <div className={styles.production__items}>
                        {
                            productionItems.map((item) => (
                                <div key={item.id} className={styles.production__item}>
                                    <div className={styles.production__picture}>
                                        <MoveParallax
                                            xStyles={[
                                                {
                                                    position: 'absolute',
                                                    inset: "0",
                                                    height: '100%',
                                                    width: "100%",
                                                },
                                                {
                                                    position: 'relative',
                                                    zIndex: 1,
                                                    width: "100%",
                                                },
                                            ]}
                                        >
                                            <div className={styles.production__bg} />
                                            <img src={item.img} alt={t(item.title)} className={styles.production__itemPic} />
                                        </MoveParallax>
                                    </div>

                                    <div className={styles.production__itemName}>
                                        <p className={styles.production__itemNumber}>{item.number}</p>

                                        <div className={styles.production__itemNameWrapper}>
                                            <H3>{t(item.title)}</H3>
                                        </div>
                                    </div>

                                    <Text>{t(item.describe)}</Text>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Production;