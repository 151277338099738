/**
 * external libs
 */
import React from 'react'
/**
 * styles
 */
import styles from './input.module.scss'

type Props = {
    placeholder?: string
    isError?: boolean
    value: string
    change: (e: React.ChangeEvent<HTMLInputElement> | { target: { value: string } }) => void
    info?: boolean
}

const TextArea: React.FC<Props> = ({
    change,
    placeholder = '',
    isError = false,
    value = '',
    info = false,
}) => {
    return (
        <textarea
            autoComplete="off"
            className={`${styles.input} ${styles.textarea} ${isError ? styles.input_error : ''} ${info ? styles.disabled : ''}`}
            placeholder={placeholder}
            value={value}
            onChange={change}
            onBlur={() => change({ target: { value: value.trim() } })}
            disabled={info}
        />
    )
}

export default TextArea
