/**
 * external libs
 */
import React, { PropsWithChildren, useRef, useState, useLayoutEffect, Children } from 'react'
import { motion, useScroll, useTransform, useAnimation } from 'framer-motion'

type Props = {
    scrollContainerRef: React.RefObject<HTMLDivElement>
    coefficient?: number
}

export const ScrollParallax: React.FC<PropsWithChildren & Props> = ({ scrollContainerRef, coefficient = 2, children }) => {
    const parallaxBlockRef = useRef<HTMLDivElement>(null)
    const { scrollX } = useScroll({ container: scrollContainerRef })
    const [parentData, setParentData] = useState({ start: 0, end: 0, width: 0 })
    const x = useTransform(scrollX, [parentData.start, parentData.end], ['0px', `${parentData.width / coefficient}px`])

    useLayoutEffect(() => {
        const updatePosition = () => {
            if (parallaxBlockRef.current) {
                const parent = parallaxBlockRef.current.parentNode
                const scrollLeft = (parent as HTMLDivElement).offsetLeft || 0
                const start = scrollLeft
                const end = start + window.innerWidth
                const width = (parent as HTMLDivElement).getBoundingClientRect().width
                setParentData({ start, end, width })
            }
        }

        updatePosition()

        window.addEventListener('resize', updatePosition)
        return () => {
            window.removeEventListener('resize', updatePosition)
        }
    }, [parallaxBlockRef])

    return (
        <motion.div
            ref={parallaxBlockRef}
            style={{
                x,
                display: 'flex',
                height: '100%',
            }}>
            {children}
        </motion.div>
    )
}

export const MoveParallax: React.FC<PropsWithChildren & { xStyles?: object[], xClasses?: string[], isHorizontal?: boolean }> = ({ children, xStyles = [], xClasses = [], isHorizontal = false }) => {
    const childrenResult = Children.toArray(children)
    const parallaxBlockRef = useRef<HTMLDivElement>(null)
    const firstAnimation = useAnimation()
    const secondAnimation = useAnimation()

    const handleMouseMove = (e: any) => {
        const { clientX, clientY } = e
        const moveX = clientX - window.innerWidth / 2
        const moveY = clientY - window.innerHeight / 2
        const offsetFactor1 = 5
        const offsetFactor2 = 20
        firstAnimation.start({
            x: moveX / offsetFactor1,
            ...(
                !isHorizontal
                ? {y: moveY / offsetFactor1}
                : {}
            )
        })
        secondAnimation.start({
            x: -moveX / offsetFactor2,
            ...(
                !isHorizontal
                ? {y: -moveY / offsetFactor2,}
                : {}
            )
        })
    }

    useLayoutEffect(() => {
        if (parallaxBlockRef.current) {
            parallaxBlockRef.current?.parentNode?.parentNode?.addEventListener('mousemove', handleMouseMove)
        }

        return () => {
            parallaxBlockRef.current?.parentNode?.parentNode?.removeEventListener('mousemove', handleMouseMove)
        }
    }, [parallaxBlockRef])

    return (
        <div ref={parallaxBlockRef} onMouseMove={(e) => handleMouseMove(e)} style={{ width: '100%', height: '100%' }}>
            <motion.div animate={firstAnimation} className={xClasses[0] || ""} style={{ display: 'flex', ...(xStyles[0] || {}) }}>
                {childrenResult[0]}
            </motion.div>
            <motion.div animate={secondAnimation} className={xClasses[1] || ""} style={{ display: 'flex', ...(xStyles[1] || {}) }}>
                {childrenResult[1]}
            </motion.div>
        </div>
    )
}
