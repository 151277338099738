/**
 * external libs
 */
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
/**
 * components
 */
import Header from './../common-components/header/Header'
import Footer from './../common-components/footer/Footer'
import Horizontal from './../common-components/horizontal/Horizontal'
import Vertical from './../common-components/vertical/Vertical'
import Teen from './../common-components/teen/Teen'
import VTeen from './../common-components/teen/VTeen'
/**
 * types
 */
import { RouteType } from '../types'
/**
 * utils
 */
import { publicRoutes } from '../routers/paths'
/**
 * styles
 */
import styles from './layouts.module.scss'

const PublicLayout: React.FC<PropsWithChildren> = () => {
    const location = useLocation()
    const [isTeen, setIsTeen] = useState(!!localStorage.getItem('isTeen'))
    const [horizontal, setHorizontal] = useState<boolean | null>(null)

    const routeData = useMemo<RouteType | undefined>(
        () => publicRoutes.find((r: RouteType) => r.path === location.pathname),
        [location]
    )

    useEffect(() => {
        const resizeHandler = () => {
            const isHorizontal = window.innerWidth >= window.innerHeight

            if (isHorizontal) {
                document.querySelector('html')?.classList.add('horizontal')
            } else {
                document.querySelector('html')?.classList.remove('horizontal')
            }

            setHorizontal(isHorizontal)
        }

        window.addEventListener('resize', resizeHandler)
        resizeHandler()

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [setHorizontal])

    useEffect(() => {
        if (isTeen) {
            localStorage.setItem('isTeen', '1')
        } else {
            localStorage.removeItem('isTeen')
        }
    }, [isTeen])

    if (horizontal === null) {
        return null
    }

    return (
        <>
            <Helmet>
                <title>{routeData?.title ?? '404'}</title>
                <meta name="description" content={routeData?.description ?? ''} />
            </Helmet>

            {!isTeen ? (
                <>{horizontal ? <Teen setIsTeen={setIsTeen} /> : <VTeen setIsTeen={setIsTeen} />}</>
            ) : (
                <>{horizontal ? <Horizontal /> : <Vertical />}</>
            )}

            {/* <div className={`publicLayout ${styles.publicLayout}`}>
                <Header
                    conditionRef={conditionRef}
                    mapRef={mapRef}
                    vacanciesRef={vacanciesRef}
                />

                <main className={styles.publicLayout__main}>
                    <Outlet />
                </main>
                
                <Footer />
            </div> */}
        </>
    )
}

export default PublicLayout
