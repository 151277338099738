/**
 * external libs
 */
import React, { PropsWithChildren, useRef, Children } from "react";
import ReactDOM from "react-dom";
import { Transition } from "react-transition-group";
/**
 * styles
*/
import styles from './portal.module.scss';

type Props = {
    open: boolean;
    vertical?: boolean;
};

const Portal: React.FC<PropsWithChildren & Props> = ({ children, open = false, vertical = false }) => {
    const portalRef = useRef(null);

    return ReactDOM.createPortal(
        <Transition nodeRef={portalRef} in={open} timeout={250} unmountOnExit={false}>
            {(state: string) => {
                if (state === "exited") {
                    return null;
                }

                return (
                    <div
                        ref={portalRef}
                        className={`portal ${styles.portal} ${vertical ? styles.portal_vertical : ""} ${state === "entered" ? styles.portal_show : ""}`}
                    >
                        {children}
                    </div>
                )
            }}
        </Transition>,
        document.body as HTMLElement
    );
};

export default Portal;
