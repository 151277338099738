/**
 * external libs
 */
import React, { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
/**
 * components
 */
import { GoldText, SubMenuLink } from '../ui'
import Portal from '../portal/Portal'
import Logo from '../../assets/svg/slide_logo.svg'
/**
 * utils
 */
import { SLIDES } from "./../horizontal/Horizontal";
/**
 * styles
 */
import styles from './header.module.scss'

type Props = {
    slide: number
}

const Header: React.FC<Props> = ({ slide }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const [open, setOpen] = useState(false)

    const menuCloseHandler = useCallback(() => {
        setOpen(false)
    }, [setOpen])

    return (
        <header className={styles.header}>
            <div className="container">
                <div className={styles.header__container}>
                    {
                        slide !== 0 && (
                            <img src={Logo} className={styles.header__logo} />
                        )
                    }

                    <div
                        className={styles.header__data}
                        onClick={() => setOpen((pre) => !pre)}
                    >
                        <div className={`${styles.burger} ${open ? styles.active : ''}`} >
                            <div className={styles.burger__line} />
                            <div className={styles.burger__line} />
                            <div className={styles.burger__line} />
                        </div>

                        <GoldText>
                            {!open ? t('menu') : t('close')}
                        </GoldText>
                    </div>
                </div>
            </div>

            <Portal open={open}>
                <nav className={`container ${styles.header__nav}`}>
                    {
                        SLIDES.map((title, idx) =>
                            <SubMenuLink
                                key={title}
                                title={t(title)}
                                onClickHandler={menuCloseHandler}
                                to={idx === slide ? location.pathname : ""}
                            />
                        )
                    }
                </nav>
            </Portal>
        </header>
    )
}

export default Header
