/**
 * external libs
 */
import React from 'react'
/**
 * styles
 */
import styles from './icon.module.scss'

type Props = {
    ico: React.ReactElement
    subClasses?: string
    filled?: boolean
    handler?: () => void
    isButton?: boolean
}

export const Ico: React.FC<Props> = ({ ico, subClasses = '', filled = false, handler, isButton = false }) => {
    const icoModern = ico
        ? React.cloneElement(ico, {
              className: styles.icobutton__ico,
          })
        : null

    return (
        <div className={`${styles.ico} ${(isButton || !!handler) ? styles.ico_handler : ''} ${filled ? styles.ico_filled : ''} ${subClasses}`}>
            {!!icoModern && icoModern}
        </div>
    )
}
