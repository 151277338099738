/**
 * external libs
 */
import React, { PropsWithChildren } from 'react'
/**
 * styles
 */
import styles from './gold.module.scss'

export const GoldText: React.FC<PropsWithChildren> = ({ children }) => {
    return <p className={styles.gold}>{children}</p>
}
