/**
 * external libs
 */
import React, { PropsWithChildren } from 'react'
/**
 * styles
 */
import styles from './text.module.scss'

export const Text: React.FC<PropsWithChildren & {title?: boolean}> = ({ children , title = false}) => {
    return <p className={`${styles.text} ${title ? styles.gold : ""}`}>{children}</p>
}

export const TextSmall: React.FC<PropsWithChildren & {title?: boolean}> = ({ children , title = false}) => {
    return <p className={`${styles.text} ${styles.text_small} ${title ? styles.gold : ""}`}>{children}</p>
}


