/**
 * external libs
 */
import React, { useCallback, useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
/**
 * components
 */
import { GoldText, SubMenuLink } from '../ui'
import Portal from '../portal/Portal'
import { LogoHeader } from '../logo/Logo'
import Logo from '../../assets/svg/slide_logo.svg'
/**
 * utils
 */
import { SLIDES } from './../horizontal/Horizontal'
import { langs } from '../../utils/i18n/i18n.constants'
/**
 * styles
 */
import styles from './header.module.scss'

const VHeader: React.FC = () => {
    const { t, i18n } = useTranslation()
    const location = useLocation()
    const [open, setOpen] = useState(false)
    const [slide, setSlide] = useState(SLIDES[0])
    const [progress, setProgress] = useState(0)

    const menuCloseHandler = useCallback(() => {
        setOpen(false)
    }, [setOpen])

    useEffect(() => {
        const changeProgress = () => {
            const maxY = document.documentElement.scrollHeight - document.documentElement.clientHeight
            const scroll = document.body.getBoundingClientRect().top

            setProgress((-scroll * 100) / maxY)
        }

        window.addEventListener('scroll', changeProgress)
        window.addEventListener('resize', changeProgress)

        return () => {
            window.removeEventListener('scroll', changeProgress)
            window.removeEventListener('resize', changeProgress)
        }
    }, [setProgress])

    return (
        <header className={styles.vheader}>
            <div className="container container_relative">
                <div className={styles.vheader__container}>
                    <div className={styles.vheader__left}>
                        <Link to="/" className={styles.vheader__logoWrapper}>
                            <LogoHeader />
                        </Link>

                        <div className={styles.vheader__langs}>
                            {Object.keys(langs).map((lang) => (
                                <button
                                    key={lang}
                                    type="button"
                                    className={`${styles.vheader__lang} ${lang === i18n.language ? styles.active : ''}`}
                                    onClick={() => i18n.changeLanguage(lang)}>
                                    {lang}
                                    <span className={styles.vheader__lang__hover}>{lang}</span>
                                </button>
                            ))}
                        </div>
                    </div>

                    <div
                        className={styles.vheader__right}
                        onClick={() => setOpen((pre) => !pre)}
                    >
                        <GoldText>{!open ? t(slide) : t('close')}</GoldText>

                        <div className={`${styles.burger} ${open ? styles.active : ''}`}>
                            <div className={styles.burger__line} />
                            <div className={styles.burger__line} />
                            <div className={styles.burger__line} />
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.vheader__progress}>
                <div className={styles.vheader__progressBar} style={{ width: `${progress}%` }} />
            </div>

            <Portal open={open} vertical={true}>
                <nav className={`container ${styles.vheader__nav}`}>
                    {SLIDES.map((title, idx) => (
                        <SubMenuLink
                            key={title}
                            title={t(title)}
                            onClickHandler={menuCloseHandler}
                            to={title === slide ? location.pathname : ''}
                        />
                    ))}
                </nav>
            </Portal>
        </header>
    )
}

export default VHeader
