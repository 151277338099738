/**
 * external libs
 */
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
/**
 * styles
 */
import styles from './link.module.scss'

type Props = {
    ico?: React.ReactElement
    title: string
    to: string
    anchor?: React.RefObject<Element>
    fillType?: 'stroke' | 'fill'
    onClickHandler?: React.Dispatch<any>
    isSubMenuLink?: boolean
    isTextLink?: boolean
    isOuter?: boolean
    isBlack?: boolean
}

const SampleLink: React.FC<Props> = ({
    ico,
    title,
    to,
    anchor,
    fillType = 'fill',
    onClickHandler,
    isSubMenuLink = false,
    isOuter = false,
    isTextLink = false,
    isBlack = false,
}) => {
    const location = useLocation()

    const icoModern = ico
        ? React.cloneElement(ico, {
              className: `${styles.link__ico} ${fillType === 'stroke' ? styles.link__ico_stroke : ''}`,
          })
        : null

    const scrollToAnchor = () => {
        if (anchor?.current) {
            anchor.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    if (isOuter) {
        return (
            <a
                href={to}
                target="_blank"
                className={`${styles.link} ${to === location.pathname && !anchor && !isTextLink ? styles.link_active : ''} ${isTextLink ? styles.link_text : ""}`}>
                {!!icoModern && <>{icoModern}</>}

                <span
                    className={`${styles.link__title} ${!!isSubMenuLink ? styles.link__menuLink : ''} ${isTextLink ? styles.link__textLink : ""}`}
                    data-text={title}>
                    {title}
                </span>
            </a>
        )
    }

    if (!anchor && !onClickHandler) {
        return (
            <Link to={to} className={`${styles.link} ${to === location.pathname && !anchor && !isTextLink ? styles.link_active : ''} ${isTextLink ? styles.link_text : ""}`}>
                {!!icoModern && <>{icoModern}</>}

                <span
                    className={`${styles.link__title} ${!!isSubMenuLink ? styles.link__subMenuLink : ''} ${isTextLink ? styles.link__textLink : ""}`}
                    data-text={title}>
                    {title}
                </span>
            </Link>
        )
    }

    return (
        <div
            className={`${styles.link} ${to === location.pathname && !anchor && !isTextLink ? styles.link_active : ''} ${isTextLink ? styles.link_text : ""}`}
            {...(onClickHandler || anchor ? { onClick: onClickHandler || scrollToAnchor } : {})}>
            {!!icoModern && <>{icoModern}</>}

            <span
                className={`${styles.link__title} ${!!isSubMenuLink ? styles.link__subMenuLink : ''} ${isBlack ? styles.link__black : ""} ${isTextLink ? styles.link__textLink : ""}`}
                data-text={title}>
                {title}
            </span>
        </div>
    )
}

export const CustomLink: React.FC<Props> = (props) => <SampleLink {...props} />
export const SubMenuLink: React.FC<Props> = (props) => <SampleLink {...props} isSubMenuLink={true} />
export const TextLink: React.FC<Props> = (props) => <SampleLink {...props} isTextLink={true} />
