/**
 * external libs
 */
import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
/**
 * components
 */
import { GoldText, H3, Button, TextSmall, TextLink } from '../ui'
import Footer from '../footer/Footer'
import Logo from '../logo/Logo'
/**
 * styles
 */
import styles from './teen.module.scss'

type Props = {
    setIsTeen: React.Dispatch<React.SetStateAction<boolean>>
}

const VTeen: React.FC<Props> = ({ setIsTeen }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.vteen}>
            <div className={styles.vteen__bg}></div>

            <Footer />

            <div className={styles.vteen__wrapper}>
                <div className={styles.vteen__content}>
                    <Link to={'/'} className={styles.vteen__link}>
                        <Logo />
                    </Link>

                    <div className={styles.vteen__info}>
                        <div className={styles.vteen__texts}>
                            <GoldText>{<Trans i18nKey="AGE_VERIFICATION" components={{ br: <br /> }} />}</GoldText>

                            <H3>{t('Are you 18 years or older')}?</H3>
                        </div>

                        <div className={styles.vteen__buttons}>
                            <Button
                                handler={() => setIsTeen(true)}
                                label={'Yes'}
                                type="button"
                                subClasses={styles.vteen__button}
                            />

                            <Button
                                handler={() => setIsTeen(false)}
                                label={'No'}
                                type="button"
                                subClasses={styles.vteen__button}
                            />
                        </div>
                    </div>

                    <p className={styles.vteen__policy}>
                        <TextSmall>
                            <Trans
                                i18nKey="agreeing_for_entering"
                                components={{
                                    br: <br />,
                                    linkterms: <TextLink title={t('Terms of Use')} to="/policy" />,
                                    linkpolicy: <TextLink title={t('Privacy Policy')} to="/policy" />,
                                }}
                            />
                        </TextSmall>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default VTeen
