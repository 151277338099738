/**
 * external libs
 */
import React from 'react'
import { useTranslation } from 'react-i18next'
/**
 * components
 */
import { ScrollParallax, MoveParallax } from '../../animate'
import { GoldText, Ico, H2, H4 } from '../../ui'
/**
 * styles
 */
import styles from './history.module.scss'

type Props = {
    containerRef: React.RefObject<HTMLDivElement>
}

const History: React.FC<Props> = ({ containerRef }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.history}>
            <div className={`container container_long ${styles.history__content}`}>
                <div className={styles.history__firstPictureWrapper}>
                    <ScrollParallax scrollContainerRef={containerRef} coefficient={4}>
                        <img src="/history_1.webp" alt="history" className={styles.history__firstPic} />
                    </ScrollParallax>
                </div>

                <div className={styles.history__texts}>
                    <GoldText>{t('introduction to the brand history')}</GoldText>
                    <H2>{t('SCHMIDT is an International brand of premium vodka')}</H2>
                </div>

                <div className={styles.history__intro}>
                    <H4>{`${t('SCHMIDT brand was born in a family of German blacksmiths who moved to the territory of modern Belarus during the Napoleonic wars of 1812-1814')}.`}</H4>
                    <img src="/history_story_bg.webp" alt="history" className={styles.history__introPic} />
                </div>

                <div className={`${styles.history__intro} ${styles.history__intro_top}`}>
                    <img src="/200_bg.webp" alt="history" className={styles.history__introPic} />
                    <H4>{`${t("For 200 years the family's heritage has been passed down from generation to generation and 6 key values are enshrined on the product label as a guarantee")}.`}</H4>
                </div>

                <div className={styles.history__master}>
                    <div className={styles.history__masterGold}></div>

                    <div className={styles.history__masterParallax}>
                        <MoveParallax
                            xStyles={[
                                {
                                    position: 'absolute',
                                    bottom: '8%',
                                    top: '8%',
                                    left: '0',
                                    height: '84%',
                                },
                                {
                                    position: 'absolute',
                                    bottom: '0',
                                    top: '0',
                                    right: '0',
                                },
                            ]}
                            isHorizontal={true}>
                            <img src="/history_master_1.svg" alt="master" className={styles.history__masterPic} />
                            <img src="/history_master_2.svg" alt="master" className={styles.history__masterPic} />
                        </MoveParallax>
                    </div>

                    <div className={styles.history__masterTextWrapper}>
                        <p className={styles.history__masterText}>
                            {t(
                                'Schmidt is a real story, a real person whose name stands behind the quality of his product'
                            )}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default History
