import * as yup from "yup";

export const contactsFormSchema = yup.object().shape({
    name: yup
        .string()
        .typeError('Это поле обязательное.')
        .required("Это поле обязательное."),
    email: yup
        .string()
        .typeError('Это поле обязательное.')
        .required("Это поле обязательное."),
    message: yup
        .array()
        .typeError('Это поле обязательное.')
        .required("Это поле обязательное."),
    file: yup
        .mixed()
        .nullable(),
});