/**
 * components
*/
import Home from "../pages/home/HomeLazy";
/**
 * types
*/
import { RouteType } from "../types";

export const publicRoutes: RouteType[] = [
    { path: "/", exact: true, component: Home, title: "Главная", description: "Главная" },
];


