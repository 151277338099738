import { langs } from "./i18n.constants";

export default {
  name: "customPath",

  lookup() {
    let found;
    if (typeof window !== "undefined") {
      const language = window.location.hash.split("/");

      if (language instanceof Array && language.length && language[0] && Object.keys(langs).includes(language[0])) {
        found = language[0];
      }
    }

    return found;
  }
};
