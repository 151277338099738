/**
 * external libs
 */
import React, { useState, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, FreeMode } from 'swiper/modules'
import { useTranslation } from 'react-i18next'
/**
 * components
 */
import { MoveParallax } from '../../animate'
import { GoldText, H4, CustomLink } from '../../ui'
/**
 * types
 */
import { Product } from "../../../types";
/**
 * styles
 */
import styles from './products.module.scss'

const bottles: Product[] = [
    {
        id: "1",
        img: "/product1.webp",
        color: "#F5F3EE",
        title: "supreme",
        advantages: [
            "only alcohol and water",
            "no sugar or additives",
            "mild taste thanks to pearl filtration",
        ],
        description: "Schmidt Supreme is our main classic line of premium vodka with a clean and smooth taste, which has won customers' trust in the world's key vodka markets",
        volumes: [0.5, 0.7, 1],
        aroma: ["clean"],
        taste: ["soft", "drinkable"],
        flavorMap: ["delicate"],
        ABV: "40%",
        bigImg: "/bigImg1.webp",
        bigImgBg: "/bigImgBg1.webp",
        bigImgExtra: "/bigImgExtra1.webp",
    },
    {
        id: "2",
        img: "/product2.webp",
        color: "#FC9B391A",
        title: "GRAPEFRUIT",
        advantages: [
            "no sugar",
            "mild taste thanks to pearl filtration",
            "universal base for cocktails",
        ],
        description: "Schmidt Grapefruit is a premium vodka with grapefruit flavor without added sugar",
        volumes: [0.5, 0.7],
        aroma: ["fresh", "juicy"],
        taste: ["soft", "natural"],
        flavorMap: ["natural sweetness", "citrus freshness"],
        ABV: "40%",
        bigImg: "/bigImg2.webp",
        bigImgBg: "/bigImgBg2.webp",
        bigImgExtra: "/bigImgExtra2.webp",
    },
    {
        id: "3",
        img: "/product3.webp",
        color: "#E95B4C1A",
        title: "CRANBERRY",
        advantages: [
            "no sugar",
            "mild taste thanks to pearl filtration",
            "universal base for cocktails",
        ],
        description: "Schmidt Cranberry is a uniquely pure cranberry vodka with no added sugar. The natural cranberry extract does not overwhelm the flavor notes, but complements the overall taste composition with its smoothness",
        volumes: [0.5, 0.7],
        aroma: ["fresh", "berry"],
        taste: ["soft", "natural"],
        flavorMap: ["tangy", "dry", "berry"],
        ABV: "40%",
        bigImg: "/bigImg3.webp",
        bigImgBg: "/bigImgBg3.webp",
        bigImgExtra: "/bigImgExtra3.webp",
    },
    {
        id: "4",
        img: "/product4.webp",
        color: "#9D79CC1A",
        title: "BLACK CURRANT",
        advantages: [
            "no sugar",
            "mild taste thanks to pearl filtration",
            "universal base for cocktails",
        ],
        description: "Schmidt Black Currant is a special vodka with one of the most popular flavors - black currant. This is a soft, creamy palette of delicate flavors of sweet ripe berries and fresh aromatic currant leaves",
        volumes: [0.7, 0.5],
        aroma: ["fresh", "juicy"],
        taste: ["soft", "natural"],
        flavorMap: ["ripe", "sweet berry"],
        ABV: "40%",
        bigImg: "/bigImg4.webp",
        bigImgBg: "/bigImgBg4.webp",
        bigImgExtra: "/bigImgExtra4.webp",
    },
];

const VProducts: React.FC = () => {
    const { t } = useTranslation()
    const [activePoint, setActivePoint] = useState<Product | null>(null)
    const sliderRef = useRef(null) as any

    const pagination = {
        clickable: true,
        renderBullet: function (index: number, className: string) {
            return `<span class=${className}>${index}</span>`
        },
    };

    return (
        <div className="container">
            <div className={`${styles.vproducts} ${!!activePoint ? styles.active : ""}`} >
                {
                    !activePoint
                    ? <div className={styles.vproduct__swiper}>
                        <Swiper
                            ref={sliderRef}
                            pagination={pagination}
                            modules={[FreeMode, Pagination]}
                            slidesPerView={2}
                            spaceBetween={24}
                            className={`swiper-main-picture ${styles.slider__slider}`}
                            loop={true}
                            autoplay={{
                                stopOnLastSlide: false,
                            }}
                            breakpoints={{
                                1250: {
                                    slidesPerView: 6.5,
                                    spaceBetween: 24,
                                },
                                950: {
                                    slidesPerView: 4.5,
                                    spaceBetween: 12,
                                },
                                320: {
                                    slidesPerView: 2.5,
                                },
                            }}
                        >
                            {
                                bottles.map(bottle => (
                                    <SwiperSlide key={bottle.id}>
                                        <div
                                            className={`${styles.vproducts__bottle} ${!!activePoint ? styles.vproducts__bottle_hide : styles.vproducts__bottle_show}`}
                                            style={{backgroundColor: bottle.color}}
                                            onClick={() => setActivePoint(bottle)}
                                        >
                                            <img
                                                src={bottle.img}
                                                alt={t(bottle.title)}
                                                className={styles.vproducts__bottlePic}
                                            />
                                        </div>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                      </div>
                    : <div className={styles.vproduct__content}>
                        <div className={styles.vproduct__picture}>
                            <MoveParallax
                                xStyles={[
                                    {
                                        position: 'absolute',
                                        inset: "2.4rem",
                                        height: 'calc(100% - 4.8rem)',
                                        with: "calc(100% - 4.8rem)",
                                    },
                                    {
                                        position: 'relative',
                                        zIndex: 1,
                                        disply: "flex",
                                        alignItems: "center",
                                        jusstifyContent: "center",
                                        with: "calc(100% - 4.8rem)",
                                        maxWidth: "32.8rem",
                                        margin: "2.4rem auto",
                                    },
                                ]}
                            >
                                <img src={activePoint.bigImgBg} alt={t(activePoint.title)} className={styles.vproduct__bg} />
                                <img src={activePoint.bigImg} alt={t(activePoint.title)} className={styles.vproduct__img} />
                            </MoveParallax>

                            <img src={activePoint.bigImgExtra} alt={t(activePoint.title)} className={styles.vproduct__logo}/>
                        </div>

                        <div className={styles.vproduct__data}>
                            <div className={styles.vproduct__topData}>
                                <H4>{t(activePoint.description)}</H4>
                            </div>

                            <div className={styles.vproduct__bottomData}>
                                <div className={styles.vproduct__lineData}>
                                    <p className={`${styles.vproduct__textData} ${styles.vproduct__textData_label}`}>{t("volumes")}</p>
                                    <p className={styles.vproduct__textData}>
                                        {
                                            activePoint.volumes.map((volume, idx) =>
                                                `${t(String(volume))}${idx === activePoint.volumes.length - 2 ? (t("l") + " and") : idx === activePoint.volumes.length - 1 ? "" : ","}`
                                            ).join(" ")
                                        }
                                    </p>
                                </div>

                                <div className={styles.vproduct__lineData}>
                                    <p className={`${styles.vproduct__textData} ${styles.vproduct__textData_label}`}>{t("aroma")}</p>
                                    <p className={styles.vproduct__textData}>
                                        {
                                            activePoint.aroma.map((aroma, idx) => t(String(aroma))).join(", ")
                                        }
                                    </p>
                                </div>

                                <div className={styles.vproduct__lineData}>
                                    <p className={`${styles.vproduct__textData} ${styles.vproduct__textData_label}`}>{t("taste")}</p>
                                    <p className={styles.vproduct__textData}>
                                        {
                                            activePoint.taste.map((taste, idx) => t(String(taste))).join(", ")
                                        }
                                    </p>
                                </div>

                                <div className={styles.vproduct__lineData}>
                                    <p className={`${styles.vproduct__textData} ${styles.vproduct__textData_label}`}>{t("flavor map")}</p>
                                    <p className={styles.vproduct__textData}>
                                        {
                                            activePoint.flavorMap.map((flavorMap, idx) => t(String(flavorMap))).join(", ")
                                        }
                                    </p>
                                </div>

                                <div className={styles.vproduct__lineData}>
                                    <p className={`${styles.vproduct__textData} ${styles.vproduct__textData_label}`}>{t("ABV")}</p>
                                    <p className={styles.vproduct__textData}>
                                        {
                                            activePoint.ABV
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className={styles.vproducts__manage} >
                    {
                        !!activePoint &&
                        <div
                            className={styles.vproducts__clear}
                            onClick={() => setActivePoint(null)}
                        >
                            <img src="/back.svg" alt="back to list" className={styles.vproducts__clearIco}/>
                            <GoldText>{t("See all Products")}</GoldText>
                        </div>
                    }

                    <div className={`${styles.vproducts__submenu} ${!!activePoint ? styles.active : ""}`}>
                        {
                            bottles.map((bottle) => (
                                <CustomLink
                                    key={bottle.id}
                                    title={t(bottle.title)}
                                    to="null"
                                    onClickHandler={() => setActivePoint(bottle)}
                                    isBlack={bottle.id === activePoint?.id || !activePoint}
                                />
                            ))
                        }
                    </div>

                    {
                        !!activePoint &&
                        <div className={styles.vproducts__liList}>
                            {
                                activePoint.advantages.map((advantage) => (
                                    <div className={styles.vproducts__li}>
                                        <GoldText>{t(advantage)}</GoldText>
                                    </div>
                                ))
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default VProducts
