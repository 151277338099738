/**
 * external libs
 */
import React from 'react'
import { useTranslation } from 'react-i18next'
/**
 * components
 */
import { ScrollParallax, MoveParallax } from '../../animate'
import { GoldText, Ico, H2, H4 } from '../../ui'
/**
 * styles
 */
import styles from './history.module.scss'

const VHistory: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className="container">
            <div className={styles.vhistory}>
                <div className={styles.vhistory__firstPicWrap}>
                    <picture className={styles.vhistory__firstPic}>
                        <source srcSet="/history_1_mob.webp" media="(max-width: 450px)" />
                        <img src="/history_1.webp" alt="home" />
                    </picture>

                    <img src="/logo_white.svg" alt="logo" className={styles.vhistory__logo} />
                </div>

                <div className={styles.vhistory__texts}>
                    <GoldText>{t('introduction to the brand history')}</GoldText>
                    <H2>{t('SCHMIDT is an International brand of premium vodka')}</H2>
                </div>

                <div className={styles.vhistory__intro}>
                    <H4>{`${t('SCHMIDT brand was born in a family of German blacksmiths who moved to the territory of modern Belarus during the Napoleonic wars of 1812-1814')}.`}</H4>
                    <img src="/history_story_bg.webp" alt="history" className={styles.vhistory__introPic} />
                </div>

                <div className={styles.vhistory__intro}>
                    <H4>{`${t("For 200 years the family's heritage has been passed down from generation to generation and 6 key values are enshrined on the product label as a guarantee")}.`}</H4>
                    <img src="/200_bg.webp" alt="history" className={styles.vhistory__introPic} />
                </div>

                <div className={styles.vhistory__master}>
                    <p className={styles.vhistory__masterText}>
                        {t(
                            'Schmidt is a real story, a real person whose name stands behind the quality of his product'
                        )}
                    </p>

                    <div className={styles.vhistory__masterParallax}>
                        <MoveParallax
                            xClasses={[styles.vhistory__masterParallax1, styles.vhistory__masterParallax2]}
                            isHorizontal={true}>
                            <img src="/history_master_1.svg" alt="master" className={`${styles.vhistory__masterPic} ${styles.vhistory__masterPic_r}`} />
                            <img src="/history_master_2_1.svg" alt="master" className={styles.vhistory__masterPic} />
                        </MoveParallax>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VHistory
