/**
 * external libs
 */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
/**
 * components
 */
import { MoveParallax } from '../../animate'
import { GoldText, H4, CustomLink } from '../../ui'
/**
 * types
 */
import { Product } from "../../../types";
/**
 * styles
 */
import styles from './products.module.scss'

type Props = {
    containerRef: React.RefObject<HTMLDivElement>
}

const bottles: Product[] = [
    {
        id: "1",
        img: "/product1.webp",
        color: "#F5F3EE",
        title: "supreme",
        advantages: [
            "only alcohol and water",
            "no sugar or additives",
            "mild taste thanks to pearl filtration",
        ],
        description: "Schmidt Supreme is our main classic line of premium vodka with a clean and smooth taste, which has won customers' trust in the world's key vodka markets",
        volumes: [0.5, 0.7, 1],
        aroma: ["clean"],
        taste: ["soft", "drinkable"],
        flavorMap: ["delicate"],
        ABV: "40%",
        bigImg: "/bigImg1.webp",
        bigImgBg: "/bigImgBg1.webp",
        bigImgExtra: "/bigImgExtra1.webp",
    },
    {
        id: "2",
        img: "/product2.webp",
        color: "#FC9B391A",
        title: "GRAPEFRUIT",
        advantages: [
            "no sugar",
            "mild taste thanks to pearl filtration",
            "universal base for cocktails",
        ],
        description: "Schmidt Grapefruit is a premium vodka with grapefruit flavor without added sugar",
        volumes: [0.5, 0.7],
        aroma: ["fresh", "juicy"],
        taste: ["soft", "natural"],
        flavorMap: ["natural sweetness", "citrus freshness"],
        ABV: "40%",
        bigImg: "/bigImg2.webp",
        bigImgBg: "/bigImgBg2.webp",
        bigImgExtra: "/bigImgExtra2.webp",
    },
    {
        id: "3",
        img: "/product3.webp",
        color: "#E95B4C1A",
        title: "CRANBERRY",
        advantages: [
            "no sugar",
            "mild taste thanks to pearl filtration",
            "universal base for cocktails",
        ],
        description: "Schmidt Cranberry is a uniquely pure cranberry vodka with no added sugar. The natural cranberry extract does not overwhelm the flavor notes, but complements the overall taste composition with its smoothness",
        volumes: [0.5, 0.7],
        aroma: ["fresh", "berry"],
        taste: ["soft", "natural"],
        flavorMap: ["tangy", "dry", "berry"],
        ABV: "40%",
        bigImg: "/bigImg3.webp",
        bigImgBg: "/bigImgBg3.webp",
        bigImgExtra: "/bigImgExtra3.webp",
    },
    {
        id: "4",
        img: "/product4.webp",
        color: "#9D79CC1A",
        title: "BLACK CURRANT",
        advantages: [
            "no sugar",
            "mild taste thanks to pearl filtration",
            "universal base for cocktails",
        ],
        description: "Schmidt Black Currant is a special vodka with one of the most popular flavors - black currant. This is a soft, creamy palette of delicate flavors of sweet ripe berries and fresh aromatic currant leaves",
        volumes: [0.7, 0.5],
        aroma: ["fresh", "juicy"],
        taste: ["soft", "natural"],
        flavorMap: ["ripe", "sweet berry"],
        ABV: "40%",
        bigImg: "/bigImg4.webp",
        bigImgBg: "/bigImgBg4.webp",
        bigImgExtra: "/bigImgExtra4.webp",
    },
];

const Products: React.FC<Props> = ({ containerRef }) => {
    const { t } = useTranslation()
    const [activePoint, setActivePoint] = useState<Product | null>(null)
    const [minWidth, setMinWidth] = useState(0)

    useEffect(() => {
        const minWidthChanger = () => {
            const w = window.innerWidth;
            const h = window.innerHeight;
            const min = h < 500 ?  (h < 400 ? 900 : 1100) : 1550
            setMinWidth(w < min ? min : w)
        }

        window.addEventListener("resize", minWidthChanger)
        minWidthChanger()

        return () => {
            window.removeEventListener("resize", minWidthChanger)
        }
    }, [setMinWidth])

    return (
        <div
            className={styles.products}
            style={
                !!activePoint && !!minWidth
                ? {minWidth: `${minWidth}px`}
                : {}
            }
        >
            <div className={`container container_long ${styles.products__content}`}>
                <div className={styles.products__submenu}>
                    {
                        !!activePoint &&
                        <div
                            className={styles.products__clear}
                            onClick={() => setActivePoint(null)}
                        >
                            <img src="/back.svg" alt="back to list" className={styles.products__clearIco}/>
                            <GoldText>{t("See all Products")}</GoldText>
                        </div>
                    }

                    {
                        bottles.map((bottle) => (
                            <React.Fragment key={bottle.id}>
                                <CustomLink
                                    title={t(bottle.title)}
                                    to="null"
                                    onClickHandler={() => setActivePoint(bottle)}
                                    isBlack={true}
                                />

                                {
                                    activePoint?.id === bottle.id &&
                                    <div className={styles.products__liList}>
                                        {
                                            bottle.advantages.map((advantage) => (
                                                <div className={styles.products__li}>
                                                    <GoldText>{t(advantage)}</GoldText>
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                            </React.Fragment>
                        ))
                    }
                </div>

                <div className={`${styles.product} ${!!activePoint ? styles.showProduct : styles.hideProduct}`}>
                    {
                        !!activePoint &&
                        <div className={styles.product__content}>
                            <div className={styles.product__picture}>
                                <MoveParallax
                                    xStyles={[
                                        {
                                            position: 'absolute',
                                            inset: "2.4rem",
                                            height: 'calc(100% - 4.8rem)',
                                            width: "calc(100% - 4.8rem)",
                                        },
                                        {
                                            position: 'absolute',
                                            disply: "flex",
                                            alignItems: "center",
                                            jusstifyContent: "center",
                                            inset: "2.4rem",
                                        },
                                    ]}
                                >
                                    <img src={activePoint.bigImgBg} alt={t(activePoint.title)} className={styles.product__bg} />
                                    <img src={activePoint.bigImg} alt={t(activePoint.title)} className={styles.product__img} />
                                </MoveParallax>

                                <img src={activePoint.bigImgExtra} alt={t(activePoint.title)} className={styles.product__logo}/>
                            </div>

                            <div className={styles.product__data}>
                                    <div className={styles.product__topData}>
                                        <H4>{t(activePoint.description)}</H4>
                                    </div>

                                    <div className={styles.product__bottomData}>
                                        <div className={styles.product__lineData}>
                                            <p className={`${styles.product__textData} ${styles.product__textData_label}`}>{t("volumes")}</p>
                                            <p className={styles.product__textData}>
                                                {
                                                    activePoint.volumes.map((volume, idx) =>
                                                        `${t(String(volume))}${idx === activePoint.volumes.length - 2 ? (t("l") + " and") : idx === activePoint.volumes.length - 1 ? "" : ","}`
                                                    ).join(" ")
                                                }
                                            </p>
                                        </div>

                                        <div className={styles.product__lineData}>
                                            <p className={`${styles.product__textData} ${styles.product__textData_label}`}>{t("aroma")}</p>
                                            <p className={styles.product__textData}>
                                                {
                                                    activePoint.aroma.map((aroma, idx) => t(String(aroma))).join(", ")
                                                }
                                            </p>
                                        </div>

                                        <div className={styles.product__lineData}>
                                            <p className={`${styles.product__textData} ${styles.product__textData_label}`}>{t("taste")}</p>
                                            <p className={styles.product__textData}>
                                                {
                                                    activePoint.taste.map((taste, idx) => t(String(taste))).join(", ")
                                                }
                                            </p>
                                        </div>

                                        <div className={styles.product__lineData}>
                                            <p className={`${styles.product__textData} ${styles.product__textData_label}`}>{t("flavor map")}</p>
                                            <p className={styles.product__textData}>
                                                {
                                                    activePoint.flavorMap.map((flavorMap, idx) => t(String(flavorMap))).join(", ")
                                                }
                                            </p>
                                        </div>

                                        <div className={styles.product__lineData}>
                                            <p className={`${styles.product__textData} ${styles.product__textData_label}`}>{t("ABV")}</p>
                                            <p className={styles.product__textData}>
                                                {
                                                    activePoint.ABV
                                                }
                                            </p>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    }
                </div>

                <div className={styles.products__bottleWrapper}>
                {
                    bottles.map(bottle => (
                        <div
                            key={bottle.id}
                            className={`${styles.products__bottle} ${!!activePoint ? styles.products__bottle_hide : styles.products__bottle_show}`}
                            style={{backgroundColor: bottle.color}}
                            onClick={() => setActivePoint(bottle)}
                        >
                            <img
                                src={bottle.img}
                                alt={t(bottle.title)}
                                className={styles.products__bottlePic}
                            />
                        </div>
                    ))
                }
                </div>
            </div>
        </div>
    )
}

export default Products
