/**
 * external libs
 */
import { useEffect, useRef, useState } from 'react'

/**
 * components
 */
import { Down, ScrollParallax, MoveParallax } from '../animate'
import VHeader from '../header/VHeader'
import Footer from '../footer/Footer'
import VHome from "../slides/home/VHome"
import VHistory from "../slides/history/VHistory"
import VProducts from "../slides/products/VProducts"
import VProduction from "../slides/production/VProduction"
import VCocktails from "../slides/cocktails/VCocktails"
import VContacts from "../slides/contacts/VContacts"
/**
 * constants
 */
import { COCKTAILS } from "../horizontal/Horizontal"
/**
 * styles
 */
import styles from './vertical.module.scss'

const Vertical = () => {

    return (
        <div className={styles.vertical}>
            <VHeader />
            <VHome />
            <VHistory />
            <VProducts />
            <VProduction />
            <VCocktails list={COCKTAILS.flat()} />
            <VContacts />
        </div>
    )
}

export default Vertical