/**
 * external libs
 */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
/**
 * components
 */
import { GoldText, Text, CustomLink } from '../../ui'
/**
 * types
 */
import { CocktailType } from "../../../types";
/**
 * styles
 */
import styles from './cocktails.module.scss'

type Props = {
    containerRef: React.RefObject<HTMLDivElement>
    list: CocktailType[]
}

const Cocktails: React.FC<Props> = ({ containerRef, list }) => {
    const { t } = useTranslation()
    const [activePoint, setActivePoint] = useState<CocktailType | null>(list[0])

    if(!activePoint) {
        return null
    }
    
    return (
        <div className={styles.cocktails}>
            <div className={`container container_long ${styles.cocktails__content}`}>
                <div className={styles.cocktails__compound}>
                    <div className={styles.cocktails__table}>
                        <div className={styles.cocktails__tableTitle}>
                            <GoldText>{t("ingredients")}</GoldText>
                        </div>

                        <div className={styles.cocktails__tableList}>
                            {
                                (activePoint.ingredients || []).map((ingredient) => (
                                    <div key={ingredient.id} className={styles.cocktails__lineData}>
                                        <p className={`${styles.cocktails__textData} ${styles.cocktails__textData_label}`}>{t(ingredient.name)}</p>
                                        <p className={styles.cocktails__textData}>
                                            {
                                                //@ts-ignore
                                                isNaN(ingredient.volume)
                                                ? t(ingredient.volume)
                                                : `${ingredient.volume} ${t("ml")}`
                                            }
                                        </p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className={styles.cocktails__table}>
                        <div className={styles.cocktails__tableTitle}>
                            <GoldText>{t("how to make")}</GoldText>
                        </div>

                        <p className={styles.cocktails__recipe}>{t(activePoint.recipe)}</p>
                    </div>
                </div>

                <img src={activePoint.image} alt={t(activePoint.title)} className={styles.cocktails__pic} />

                <div className={styles.cocktails__right}>
                    <GoldText>{t(activePoint.principal)}</GoldText>
                    <div className={styles.cocktails__rightTop}></div>

                    <div className={styles.cocktails__submenu}>
                        {
                            list.map((item) => (
                                <CustomLink
                                    key={item.id}
                                    title={t(item.title)}
                                    to="null"
                                    onClickHandler={() => setActivePoint(item)}
                                    isBlack={item.id === activePoint?.id}
                                />
                            ))
                        }
                    </div>

                    <div className={styles.cocktails__rightBottom}></div>
                    <Text>{t(activePoint.describe)}</Text>
                </div>
            </div>
        </div>
    )
}

export default Cocktails