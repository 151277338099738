/**
 * external libs
 */
import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
/**
 * components
 */
import { GoldText, H3, Button, TextSmall, TextLink } from '../ui'
import Footer from '../footer/Footer'
import Logo from '../logo/Logo'
/**
 * styles
 */
import styles from './teen.module.scss'

type Props = {
    setIsTeen: React.Dispatch<React.SetStateAction<boolean>>
}

const Teen: React.FC<Props> = ({ setIsTeen }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.teen}>
            <Link to={'/'} className={styles.teen__link}>
                <Logo />
            </Link>

            <div className={styles.teen__bg}></div>

            <div className={styles.teen__wrapper}>
                <div className={styles.teen__container}>
                    <div className={styles.teen__content}>
                        <div className={styles.teen__info}>
                            <div className={styles.teen__texts}>
                                <GoldText>{<Trans i18nKey="AGE_VERIFICATION" components={{ br: <br /> }} />}</GoldText>

                                <H3>{t('Are you 18 years or older')}?</H3>
                            </div>

                            <div className={styles.teen__buttons}>
                                <Button
                                    handler={() => setIsTeen(true)}
                                    label={'Yes'}
                                    type="button"
                                    subClasses={styles.teen__button}
                                />

                                <Button
                                    handler={() => setIsTeen(false)}
                                    label={'No'}
                                    type="button"
                                    subClasses={styles.teen__button}
                                />
                            </div>
                        </div>

                        <TextSmall>
                            <Trans
                                i18nKey="agreeing_for_entering"
                                components={{
                                    br: <br />,
                                    linkterms: <TextLink title={t('Terms of Use')} to="/policy" />,
                                    linkpolicy: <TextLink title={t('Privacy Policy')} to="/policy" />,
                                }}
                            />
                        </TextSmall>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Teen
