/**
 * external libs
 */
import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, FreeMode } from 'swiper/modules'
import { useTranslation } from 'react-i18next'
/**
 * components
 */
import { GoldText, H2, H3, Text } from '../../ui'
/**
 * types
 */
import { ProductionType } from "../../../types";
/**
 * styles
 */
import styles from './production.module.scss'


const productionItems: ProductionType[] = [
    {
        id: "1",
        title: "alcohol",
        img: "/prod1.webp",
        number: "01",
        describe: "We use premium grain alcohol produced at our own plants from raw materials of highest quality",
    },
    {
        id: "2",
        title: "water",
        img: "/prod2.webp",
        number: "02",
        describe: "Water is extracted from our own well located on the distillery territory at a depth of more than 220 meters. It is gently filtered without thermal treatment and softened through additional ion filtration to give it a special smoothness",
    },
    {
        id: "3",
        title: "blending",
        img: "/prod3.webp",
        number: "03",
        describe: "Alcohol is mixed with water under high pressure and is cooled to minus 15 degrees for additional segmentation of possible impurities",
    },
    {
        id: "4",
        title: "filtration",
        img: "/prod4.webp",
        number: "04",
        describe: "Schmidt vodka is filtered without additional pressure through a charcoal filter made from maple, coconut palm and birch wood. Then vodka undergoes a unique filtration process through natural white pearls giving vodka its unique velvety smoothness",
    },
]

const VProduction: React.FC = () => {
    const { t } = useTranslation()
    const sliderRef = useRef(null) as any

    const pagination = {
        clickable: true,
        renderBullet: function (index: number, className: string) {
            return `<span class=${className}>${index}</span>`
        },
    };

    return (
        <div className="container">
            <div className={styles.vproduction} >
                <picture className={styles.vproduction__firstPic}>
                    <source srcSet="/production_mob.webp" media="(max-width: 450px)" />
                    <img src="/production.webp" alt="home" />
                </picture>

                <div className={styles.vproduction__title}>
                    <GoldText>{t("let's explore")}</GoldText>
                    <H2>{t("production")}</H2>
                </div>

                <div className={styles.vproduction__swiper}>
                    <Swiper
                        ref={sliderRef}
                        pagination={pagination}
                        modules={[FreeMode, Pagination]}
                        slidesPerView={2}
                        spaceBetween={24}
                        className={`swiper-main-picture ${styles.slider__slider}`}
                        loop={true}
                        autoplay={{
                            stopOnLastSlide: false,
                        }}
                        breakpoints={{
                            1250: {
                                slidesPerView: 3,
                                spaceBetween: 24,
                            },
                            950: {
                                slidesPerView: 2,
                                spaceBetween: 12,
                            },
                            320: {
                                slidesPerView: 1,
                            },
                        }}
                    >
                        {
                            productionItems.map(item => (
                                <SwiperSlide key={item.id}>
                                    <div className={styles.vproduction__item}>
                                        <div className={styles.vproduction__picture}>
                                            <img src={item.img} alt={t(item.title)} className={styles.vproduction__itemPic} />
                                        </div>

                                        <div className={styles.vproduction__itemName}>
                                            <p className={styles.vproduction__itemNumber}>{item.number}</p>

                                            <div className={styles.vproduction__itemNameWrapper}>
                                                <H3>{t(item.title)}</H3>
                                            </div>
                                        </div>

                                        <Text>{t(item.describe)}</Text>
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default VProduction;