/**
 * external libs
 */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
/**
 * components
 */
import { GoldText, Text, CustomLink } from '../../ui'
/**
 * types
 */
import { CocktailType } from "../../../types";
/**
 * styles
 */
import styles from './cocktails.module.scss'

type Props = {
    list: CocktailType[]
}

const VCocktails: React.FC<Props> = ({ list }) => {
    const { t } = useTranslation()
    const [activePoint, setActivePoint] = useState<CocktailType | null>(list[0])

    if(!activePoint) {
        return null
    }
    
    return (
        <div className="container">
            <div className={styles.vcocktails}>
                <div className={styles.vcocktails__manage}>
                    <GoldText>{t(activePoint.principal)}</GoldText>

                    <div className={styles.vcocktails__submenu}>
                        {
                            list.map((cocktail) => (
                                <CustomLink
                                    key={cocktail.id}
                                    title={t(cocktail.title)}
                                    to="null"
                                    onClickHandler={() => setActivePoint(cocktail)}
                                    isBlack={cocktail.id === activePoint?.id || !activePoint}
                                />
                            ))
                        }
                    </div>
                </div>

                <div className={styles.vcocktails__data}>
                    <div className={styles.vcocktails__common}>
                        <picture className={styles.vcocktails__pic}>
                            {/* Need add min pic */}
                            <source srcSet={activePoint.image} media="(max-width: 450px)" />
                            <img src={activePoint.image} alt="home" />
                        </picture>

                        <div className={styles.vcocktails__descr}>
                            <Text>{t(activePoint.describe)}</Text>
                        </div>
                    </div>

                    <div className={styles.vcocktails__params}>
                        <div className={styles.vcocktails__table}>
                            <div className={styles.vcocktails__tableTitle}>
                                <GoldText>{t("ingredients")}</GoldText>
                            </div>

                            <div className={styles.vcocktails__tableList}>
                                {
                                    (activePoint.ingredients || []).map((ingredient) => (
                                        <div key={ingredient.id} className={styles.vcocktails__lineData}>
                                            <p className={`${styles.vcocktails__textData} ${styles.vcocktails__textData_label}`}>{t(ingredient.name)}</p>
                                            <p className={styles.vcocktails__textData}>
                                                {
                                                    //@ts-ignore
                                                    isNaN(ingredient.volume)
                                                    ? t(ingredient.volume)
                                                    : `${ingredient.volume} ${t("ml")}`
                                                }
                                            </p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <div className={styles.vcocktails__table}>
                            <div className={styles.vcocktails__tableTitle}>
                                <GoldText>{t("how to make")}</GoldText>
                            </div>

                            <p className={styles.vcocktails__recipe}>{t(activePoint.recipe)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VCocktails