import i18n from "i18next";
import customPath from "./customPath";
import ChainedBackend from "i18next-chained-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { langs } from "./i18n.constants";
import { en, ru } from "./i18n.translations";

const languageDetector = new LanguageDetector();
languageDetector.addDetector(customPath);

i18n
  .use(ChainedBackend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    supportedLngs: Object.keys(langs),
    debug: false,
    resources: {
      [langs.en]: en,
      [langs.ru]: ru,
    },
    detection: {
      order: ["customPath", "localStorage", "cookie"],
      caches: ["localStorage", "cookie"]
    },
    interpolation: {
      escapeValue: false
    },
    defaultNS: "common"
  });

export default i18n;
