/**
 * external libs
*/
import React, { PropsWithChildren } from "react";
/**
 * styles
*/
import styles from './field.module.scss';

type Props = {
    error?: string;
    noError?: boolean;
    helper?: string;
    subClass?: string;
};

const Field: React.FC<PropsWithChildren & Props> = ({ children,  error, subClass, helper, noError = false }) => {
    const child = React.Children.only(children) as any;

    const childComponent = noError ? child : React.cloneElement(child, {
        isError: !!error,
    });

    return (
        <div className={`${styles.field} ${subClass || ""}`}>
            {childComponent}

            {
                !!error &&
                <p className={styles.field__error}>{error}</p>
            }

            {
                !error && !!helper &&
                <p className={`${styles.field__error} ${styles.field__error_helper}`}>{helper}</p>
            }
        </div>
    );
};

export default Field; 