/**
 * external libs
 */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
/**
 * components
 */
import { GoldText, H2 } from '../../ui'
/**
 * styles
 */
import styles from './home.module.scss'

const VHome: React.FC = () => {
    const { t } = useTranslation()
    const [start, setStart] = useState(false)

    useEffect(() => {
        setTimeout(() => setStart(true), 500)
    }, [setStart])

    return (
        <div className="container">
            <div className={styles.vhome}>
                <div className={styles.vhome__bottleWrapper}>
                    <picture className={styles.vhome__bottle}>
                        <source srcSet="/home_bottle_mob.webp" media="(max-width: 450px)" />
                        <img src="/home_bottle.webp" alt="home" />
                    </picture>

                    <div className={styles.vhome__bg}>
                        <div className={`${styles.vhome__bgHover} ${start ? styles.vhome__bgHover_hide : ''}`}></div>
                    </div>
                </div>

                <div className={styles.vhome__texts}>
                    <H2>{t('Schmidt — premium vodka')}</H2>

                    <div className={styles.vhome__advantages}>
                        <div className={styles.vhome__advantage}>
                            <img
                                src="/home_advantage1.webp"
                                alt={t('Pure composition without sugar')}
                                className={styles.vhome__advantageIco}
                            />
                            <GoldText>{t('Pure composition without sugar')}</GoldText>
                        </div>

                        <div className={styles.vhome__advantage}>
                            <img
                                src="/home_advantage2.webp"
                                alt={t('Unique smoothness thanks to filtration through pearls')}
                                className={styles.vhome__advantageIco}
                            />
                            <GoldText>{t('Unique smoothness thanks to filtration through pearls')}</GoldText>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VHome
