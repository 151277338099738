/**
 * external libs
 */
import React from 'react'
import { useTranslation } from 'react-i18next'
/**
 * utils
 */
import { SLIDES } from './../horizontal/Horizontal'
import { langs } from '../../utils/i18n/i18n.constants'
/**
 * components
 */
import { GoldText, Ico } from '../ui'
/**
 * styles
 */
import styles from './footer.module.scss'

type Props = {
    title?: string
    isSlide?: boolean
    slide?: number
}

const Footer: React.FC<Props> = ({ title = '', isSlide = false, slide }) => {
    const { t, i18n } = useTranslation()

    return (
        <footer className={`${styles.footer} ${isSlide ? styles.footer_slide : ''}`}>
            <div className={`container ${styles.footer__container}`}>
                <GoldText>{(!!slide || slide === 0) && !title ? t(SLIDES[slide]) : title ? t(title) : ''}</GoldText>

                <div className={`${styles.footer__menu} ${(!title && !isSlide) ? styles.footer__menu_long : ''}`}>
                    <a href="mailto:mail@mail.com" className={styles.footer__mail}>
                        <Ico
                            ico={
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.6155 19C4.15517 19 3.77083 18.8458 3.4625 18.5375C3.15417 18.2292 3 17.8448 3 17.3845L3 6.6155C3 6.15517 3.15417 5.77083 3.4625 5.4625C3.77083 5.15417 4.15517 5 4.6155 5L19.3845 5C19.8448 5 20.2292 5.15417 20.5375 5.4625C20.8458 5.77083 21 6.15517 21 6.6155L21 17.3845C21 17.8448 20.8458 18.2292 20.5375 18.5375C20.2292 18.8458 19.8448 19 19.3845 19L4.6155 19ZM12 12.1155L4 6.8845L4 17.3845C4 17.564 4.05767 17.7115 4.173 17.827C4.2885 17.9423 4.436 18 4.6155 18L19.3845 18C19.564 18 19.7115 17.9423 19.827 17.827C19.9423 17.7115 20 17.564 20 17.3845L20 6.8845L12 12.1155ZM12 11L19.6923 6L4.30775 6L12 11ZM4 6.8845L4 6L4 17.3845C4 17.564 4.05767 17.7115 4.173 17.827C4.2885 17.9423 4.436 18 4.6155 18L4 18L4 6.8845Z" />
                                </svg>
                            }
                            isButton={true}
                        />
                    </a>

                    <div className={styles.footer__langs}>
                        {Object.keys(langs).map((lang) => (
                            <button
                                key={lang}
                                type="button"
                                className={`${styles.footer__lang} ${lang === i18n.language ? styles.active : ''}`}
                                onClick={() => i18n.changeLanguage(lang)}>
                                {lang}
                                <span className={styles.footer__lang__hover}>{lang}</span>
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
