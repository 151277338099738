import { namespaces } from './i18n.constants'
import { LangType } from '../../types'

export const en: LangType = {
    [namespaces.common]: {
        'Are you 18 years or older': 'Are you 18 years or older',
        AGE_VERIFICATION: 'AGE VERIFICATION:<br></br>You must be 18 years of age or older to access this site',
        agreeing_for_entering:
            'By entering this site you are agreeing<br></br>to the <linkterms></linkterms> and <linkpolicy></linkpolicy>',
        'Terms of Use': 'Terms of Use',
        'Privacy Policy': 'Privacy Policy',
        menu: 'menu',
        close: 'close',
        home: 'home',
        'brand history': 'brand history',
        products: 'products',
        production: 'production',
        cocktails: 'cocktails',
        contacts: 'contacts',
        'scroll on this way': 'scroll on this way',
        'Schmidt — premium vodka': 'Schmidt — premium vodka',
        'Pure composition without sugar': 'Pure composition without sugar',
        'Unique smoothness thanks to filtration through pearls':
            'Unique smoothness thanks to filtration through pearls',
        'introduction to the brand history': 'introduction to the brand history',
        'SCHMIDT is an International brand of premium vodka': 'SCHMIDT is an International brand of premium vodka',
        'SCHMIDT brand was born in a family of German blacksmiths who moved to the territory of modern Belarus during the Napoleonic wars of 1812-1814':
            'SCHMIDT brand was born in a family of German blacksmiths who moved to the territory of modern Belarus during the Napoleonic wars of 1812-1814',
        "For 200 years the family's heritage has been passed down from generation to generation and 6 key values are enshrined on the product label as a guarantee":
            "For 200 years the family's heritage has been passed down from generation to generation and 6 key values are enshrined on the product label as a guarantee",
        'Schmidt is a real story, a real person whose name stands behind the quality of his product':
            'Schmidt is a real story, a real person whose name stands behind the quality of his product',
        supreme: 'supreme',
        GRAPEFRUIT: 'GRAPEFRUIT',
        CRANBERRY: 'CRANBERRY',
        'BLACK CURRANT': 'BLACK CURRANT',
        l: 'l',
        'only alcohol and water': 'only alcohol and water',
        'no sugar or additives': 'no sugar or additives',
        'mild taste thanks to pearl filtration': 'mild taste thanks to pearl filtration',
        "Schmidt Supreme is our main classic line of premium vodka with a clean and smooth taste, which has won customers' trust in the world's key vodka markets":
            "Schmidt Supreme is our main classic line of premium vodka with a clean and smooth taste, which has won customers' trust in the world's key vodka markets",
        clean: 'clean',
        soft: 'soft',
        drinkable: 'drinkable',
        delicate: 'delicate',
        'no sugar': 'no sugar',
        'universal base for cocktails': 'universal base for cocktails',
        'Schmidt Grapefruit is a premium vodka with grapefruit flavor without added sugar':
            'Schmidt Grapefruit is a premium vodka with grapefruit flavor without added sugar',
        fresh: 'fresh',
        juicy: 'juicy',
        natural: 'natural',
        'natural sweetness': 'natural sweetness',
        'citrus freshness': 'citrus freshness',
        'Schmidt Cranberry is a uniquely pure cranberry vodka with no added sugar. The natural cranberry extract does not overwhelm the flavor notes, but complements the overall taste composition with its smoothness':
            'Schmidt Cranberry is a uniquely pure cranberry vodka with no added sugar. The natural cranberry extract does not overwhelm the flavor notes, but complements the overall taste composition with its smoothness',
        berry: 'berry',
        tangy: 'tangy',
        dry: 'dry',
        'Schmidt Black Currant is a special vodka with one of the most popular flavors - black currant. This is a soft, creamy palette of delicate flavors of sweet ripe berries and fresh aromatic currant leaves':
            'Schmidt Black Currant is a special vodka with one of the most popular flavors - black currant. This is a soft, creamy palette of delicate flavors of sweet ripe berries and fresh aromatic currant leaves',
        ripe: 'ripe',
        'sweet berry': 'sweet berry',
        'See all Products': 'See all Products',
        volumes: 'volumes',
        aroma: 'aroma',
        taste: 'taste',
        'flavor map': 'flavor map',
        ABV: 'ABV',
        "let's explore": "let's explore",
        alcohol: 'alcohol',
        'We use premium grain alcohol produced at our own plants from raw materials of highest quality':
            'We use premium grain alcohol produced at our own plants from raw materials of highest quality',
        water: 'water',
        'Water is extracted from our own well located on the distillery territory at a depth of more than 220 meters. It is gently filtered without thermal treatment and softened through additional ion filtration to give it a special smoothness':
            'Water is extracted from our own well located on the distillery territory at a depth of more than 220 meters. It is gently filtered without thermal treatment and softened through additional ion filtration to give it a special smoothness',
        blending: 'blending',
        'Alcohol is mixed with water under high pressure and is cooled to minus 15 degrees for additional segmentation of possible impurities':
            'Alcohol is mixed with water under high pressure and is cooled to minus 15 degrees for additional segmentation of possible impurities',
        filtration: 'filtration',
        'Schmidt vodka is filtered without additional pressure through a charcoal filter made from maple, coconut palm and birch wood. Then vodka undergoes a unique filtration process through natural white pearls giving vodka its unique velvety smoothness':
            'Schmidt vodka is filtered without additional pressure through a charcoal filter made from maple, coconut palm and birch wood. Then vodka undergoes a unique filtration process through natural white pearls giving vodka its unique velvety smoothness',
        'SCHMIDT SUPREME': 'SCHMIDT SUPREME',
        'Schmidt dry martini': 'Schmidt dry martini',
        "The highest quality ingredients, their delicate balance and perfect serving are the three components of this legendary cocktail, which in many ways has become an indicator of the bartender's skill":
            "The highest quality ingredients, their delicate balance and perfect serving are the three components of this legendary cocktail, which in many ways has become an indicator of the bartender's skill",
        'Schmidt Supreme': 'Schmidt Supreme',
        'Dry vermouth': 'Dry vermouth',
        'Cool thoroughly and combine vermouth and vodka in a mixing glass, strain into a cocktail glass and top with an olive on a skewer':
            'Cool thoroughly and combine vermouth and vodka in a mixing glass, strain into a cocktail glass and top with an olive on a skewer',
        'SCHMIDT WHITE RUSSIAN': 'SCHMIDT WHITE RUSSIAN',
        'Schmidt White Russian is ideal premium classic. It has a creamy taste, the softness of which is emphasized by Schmidt vodka':
            'Schmidt White Russian is ideal premium classic. It has a creamy taste, the softness of which is emphasized by Schmidt vodka',
        'Vanilla cream': 'Vanilla cream',
        'Coffee liqueur': 'Coffee liqueur',
        'Pour coffee liqueur and vodka into a rocks glass, stir and add cream. serving everything with a slice of chocolate for the perfect garnish':
            'Pour coffee liqueur and vodka into a rocks glass, stir and add cream. serving everything with a slice of chocolate for the perfect garnish',
        'SCHMIDT GRASSHOPPER': 'SCHMIDT GRASSHOPPER',
        'Schmidt Grasshopper is a creamy, smooth, digestive cocktail with a pleasant, refreshing minty aftertaste. The ideal accompaniment for it would be bitter and rich chocolate':
            'Schmidt Grasshopper is a creamy, smooth, digestive cocktail with a pleasant, refreshing minty aftertaste. The ideal accompaniment for it would be bitter and rich chocolate',
        Cream: 'Cream',
        'Mint liqueur': 'Mint liqueur',
        'Decorate the rim of a cocktail or sparkling glass with melted milk chocolate. After this, mix all the ingredients in a shaker with plenty of ice and carefully filter into a glass':
            'Decorate the rim of a cocktail or sparkling glass with melted milk chocolate. After this, mix all the ingredients in a shaker with plenty of ice and carefully filter into a glass',
        'SCHMIDT GRAPEFRUIT': 'SCHMIDT GRAPEFRUIT',
        'SCHMIDT PALOMA': 'SCHMIDT PALOMA',
        'One of the most charismatic and atmospheric drinks in the whole world. A cocktail that will certainly create a bright mood':
            'One of the most charismatic and atmospheric drinks in the whole world. A cocktail that will certainly create a bright mood',
        'Schmidt Grapefruit': 'Schmidt Grapefruit',
        Campari: 'Campari',
        'Grapefruit juice': 'Grapefruit juice',
        'Lime juice': 'Lime juice',
        Garnish: 'Garnish',
        paprika: 'paprika',
        'Mix Schmidt Grapefruit, fresh lime and grapefruit juices in a shaker with ice, add a little orange bitter and a pinch of salt, pour into a tall glass with ice and add a splash of soda. The edge of the glass can be decorated with salted or smoked paprika':
            'Mix Schmidt Grapefruit, fresh lime and grapefruit juices in a shaker with ice, add a little orange bitter and a pinch of salt, pour into a tall glass with ice and add a splash of soda. The edge of the glass can be decorated with salted or smoked paprika',
        'SCHMIDT SMASH': 'SCHMIDT SMASH',
        'Always fresh, always summer and always modern. Scmidt Smash is an easy to make cocktail that is perfect for dinner or a party. Fresh basil, grapefruit vodka, juicy lemon and vanilla combine perfectly in an easy and popular mix':
            'Always fresh, always summer and always modern. Scmidt Smash is an easy to make cocktail that is perfect for dinner or a party. Fresh basil, grapefruit vodka, juicy lemon and vanilla combine perfectly in an easy and popular mix',
        'Lemon juice': 'Lemon juice',
        'Vanilla syrup': 'Vanilla syrup',
        Basil: 'Basil',
        '12 medium leaves': '12 medium leaves',
        'egg white': 'egg white',
        Decoration: 'Decoration',
        grapefruit: 'grapefruit',
        ICE: 'ICE',
        'SCHMIDT CRANBERRY': 'SCHMIDT CRANBERRY',
        'SCHMIDT VODKA TONIC': 'SCHMIDT VODKA TONIC',
        'Schmidt Vodka Tonic is an impeccable mix with a clean, bittersweet, refreshing finish':
            'Schmidt Vodka Tonic is an impeccable mix with a clean, bittersweet, refreshing finish',
        'Schmidt Cranberry': 'Schmidt Cranberry',
        Tonic: 'Tonic',
        'currant sprig': 'currant sprig',
        'Pour vodka and tonic into a tall glass with ice. Stir and garnish with a sprig of bright currants, frozen cranberries or a spike':
            'Pour vodka and tonic into a tall glass with ice. Stir and garnish with a sprig of bright currants, frozen cranberries or a spike',
        'SCHMIDT CLUB': 'SCHMIDT CLUB',
        'A twist on the famous Clover Club cocktail. We prepare it with Schmidt Cranberry base and add the juice of thawed cranberries. The airy and light texture of this drink is perfect for the bright and juicy berry flavor of cranberries':
            'A twist on the famous Clover Club cocktail. We prepare it with Schmidt Cranberry base and add the juice of thawed cranberries. The airy and light texture of this drink is perfect for the bright and juicy berry flavor of cranberries',
        'Cranberry juice': 'Cranberry juice',
        'Sugar syrup': 'Sugar syrup',
        'Egg White': 'Egg White',
        'Thaw and squeeze cranberries, add this juice and the rest of the ingredients to a shaker, mix vigorously and then filter into a cocktail glass. Garnish the drink with mint or sprinkle with cinnamon.':
            'Thaw and squeeze cranberries, add this juice and the rest of the ingredients to a shaker, mix vigorously and then filter into a cocktail glass. Garnish the drink with mint or sprinkle with cinnamon.',
        'SCHMIDT Black Currant': 'SCHMIDT Black Currant',
        'SCHMIDT BLACK CURRANT MOJITO': 'SCHMIDT BLACK CURRANT MOJITO',
        'A variation of the famous refreshing cocktail, which harmoniously combines bright citrus notes, minty freshness and the sweetness of ripe currants':
            'A variation of the famous refreshing cocktail, which harmoniously combines bright citrus notes, minty freshness and the sweetness of ripe currants',
        'Schmidt Black Currant': 'Schmidt Black Currant',
        'Lime Juice': 'Lime Juice',
        mint: 'mint',
        '8 leaves': '8 leaves',
        Lime: 'Lime',
        '4 wedges': '4 wedges',
        'Blackcurrant jam': 'Blackcurrant jam',
        '1 teaspoon': '1 teaspoon',
        soda: 'soda',
        'Invigorate the mint with an intense clap of your hands. Place mint, lime wedges and ice in a tall glass. Pour in the remaining ingredients and mix thoroughly. Garnish the drink with a sprig of mint and enjoy!':
            'Invigorate the mint with an intense clap of your hands. Place mint, lime wedges and ice in a tall glass. Pour in the remaining ingredients and mix thoroughly. Garnish the drink with a sprig of mint and enjoy!',
        'SCHMIDT BLACK CLUB': 'SCHMIDT BLACK CLUB',
        'A juicy mix based on soft berry vodka, lime juice and honey syrup. The airy foam from thawed blackcurrant juice gives the drink a special delicacy, creating a light and so drinkable texture of the cocktail.':
            'A juicy mix based on soft berry vodka, lime juice and honey syrup. The airy foam from thawed blackcurrant juice gives the drink a special delicacy, creating a light and so drinkable texture of the cocktail.',
        'honey syrup': 'honey syrup',
        'Thawed blackcurrant juice': 'Thawed blackcurrant juice',
        'Thaw and squeeze blackcurrants, add this juice and the rest of the ingredients to a shaker, mix vigorously and then filter into a cocktail glass. Garnish the drink with mint or sprinkle with cinnamon.':
            'Thaw and squeeze blackcurrants, add this juice and the rest of the ingredients to a shaker, mix vigorously and then filter into a cocktail glass. Garnish the drink with mint or sprinkle with cinnamon.',
        'ingredients': 'ingredients',
        'how to make': 'how to make',
        'ml': 'ml',
        'Remove 12 fresh green basil leaves from the stem, place them in a shaker and add the remaining ingredients and ice. Shake everything well, then filter into a glass with ice and garnish with a slice of grapefruit.': 'Remove 12 fresh green basil leaves from the stem, place them in a shaker and add the remaining ingredients and ice. Shake everything well, then filter into a glass with ice and garnish with a slice of grapefruit.',
        'contact details': 'contact details',
        'SchmidtSpirits OÜ': 'SchmidtSpirits OÜ',
        '50-201, Vesivarava str, 10152 Tallinn, Estonia': '50-201, Vesivarava str, 10152 Tallinn, Estonia',
        'phone': 'phone',
        'email us': 'email us',
        'name': 'name',
        'email': 'email',
        'message': 'message',
        'file, max 10mb': 'file, max 10mb',
        'send': 'send',
        '© SchmidtSpiritsOÜ 2024': '© SchmidtSpiritsOÜ 2024',
        'privacy policy': 'пprivacy policy',
        'cookies policy': 'cookies policy',
    },
}

export const ru: LangType = {
    [namespaces.common]: {
        'Are you 18 years or older': 'Вам 18 лет или больше',
        AGE_VERIFICATION: 'ПРОВЕРКА ВОЗРАСТА:<br></br>Для доступа к этому сайту вам должно быть 18 лет или больше',
        agreeing_for_entering:
            'Используя этот сайт, вы соглашаетесь<br></br>с <linkterms></linkterms> и <linkpolicy></linkpolicy>',
        'Terms of Use': 'Условиями',
        'Privacy Policy': 'Политикой конфиденциальности',
        menu: 'меню',
        close: 'закрыть',
        home: 'главная',
        'brand history': 'история бренда',
        products: 'продукция',
        production: 'производство',
        cocktails: 'коктели',
        contacts: 'контакты',
        'scroll on this way': 'скроль сюда',
        'Schmidt — premium vodka': 'Шмидт — водка премиум-класса',
        'Pure composition without sugar': 'Чистый состав без сахара',
        'Unique smoothness thanks to filtration through pearls':
            'Уникальная нежность благодаря фильтрации через жемчуг',
        'introduction to the brand history': 'знакомство с историей бренда',
        'SCHMIDT is an International brand of premium vodka': 'SCHMIDT — международный бренд премиум водки',
        'SCHMIDT brand was born in a family of German blacksmiths who moved to the territory of modern Belarus during the Napoleonic wars of 1812-1814':
            'Бренд SCHMIDT зародился в семье немецких кузнецов, переселившихся на территорию современной Беларуси во время наполеоновских войн 1812-1814 гг',
        "For 200 years the family's heritage has been passed down from generation to generation and 6 key values are enshrined on the product label as a guarantee":
            'В ходе 200 лет наследие семьи передается из поколения в поколение, а 6 ключевых ценностей закреплены на этикетке продукта как гарантия',
        'Schmidt is a real story, a real person whose name stands behind the quality of his product':
            'Schmidt — это реальная история о реальном человеке, чье имя стоит за качеством его продукции.',
        supreme: 'supreme',
        GRAPEFRUIT: 'GRAPEFRUIT',
        CRANBERRY: 'CRANBERRY',
        'BLACK CURRANT': 'BLACK CURRANT',
        l: 'л',
        'only alcohol and water': 'только алкоголь и вода',
        'no sugar or additives': 'без сахара и добавок',
        'mild taste thanks to pearl filtration': 'мягкий вкус благодаря жемчужной фильтрации',
        "Schmidt Supreme is our main classic line of premium vodka with a clean and smooth taste, which has won customers' trust in the world's key vodka markets":
            'Schmidt Supreme — наша основная классическая линейка премиальной водки с чистым и мягким вкусом, завоевавшая доверие потребителей на ключевых мировых рынках водки',
        clean: 'чистый',
        soft: 'мягкий',
        drinkable: 'питьевой',
        delicate: 'деликатный',
        'no sugar': 'без сахара',
        'universal base for cocktails': 'универсальная основа для коктейлей',
        'Schmidt Grapefruit is a premium vodka with grapefruit flavor without added sugar':
            'Schmidt Grapefruit — премиальная водка со вкусом грейпфрута без сахара.',
        fresh: 'освежающий',
        juicy: 'сочный',
        natural: 'натуральный',
        'natural sweetness': 'натуральная сладость',
        'citrus freshness': 'цитрусовая свежесть',
        'Schmidt Cranberry is a uniquely pure cranberry vodka with no added sugar. The natural cranberry extract does not overwhelm the flavor notes, but complements the overall taste composition with its smoothness':
            'Schmidt Cranberry — уникальная чистая клюквенная водка без добавления сахара. Натуральный экстракт клюквы не перебивает вкусовые ноты, а дополняет общую вкусовую композицию своей мягкостью',
        berry: 'ягодный',
        tangy: 'резкий',
        dry: 'сухой',
        'Schmidt Black Currant is a special vodka with one of the most popular flavors - black currant. This is a soft, creamy palette of delicate flavors of sweet ripe berries and fresh aromatic currant leaves':
            'Schmidt Black Currant — особая водка с одним из самых популярных вкусов — черной смородиной. Это мягкая, сливочная палитра нежных вкусов сладких спелых ягод и свежих ароматных листьев смородины',
        ripe: 'зрелый',
        'sweet berry': 'сладкая ягода',
        'See all Products': 'Все продукты',
        volumes: 'литраж',
        aroma: 'аромат',
        taste: 'вкус',
        'flavor map': 'карта вкуса',
        ABV: 'крепость',
        "let's explore": 'давайте посмотрим',
        alcohol: 'алкоголь',
        'We use premium grain alcohol produced at our own plants from raw materials of highest quality':
            'Мы используем пшеничный спирт премиум-класса, произведенный на собственных заводах из сырья высочайшего качества',
        water: 'вода',
        'Water is extracted from our own well located on the distillery territory at a depth of more than 220 meters. It is gently filtered without thermal treatment and softened through additional ion filtration to give it a special smoothness':
            'Вода добывается из собственной скважины, расположенной на территории завода, на глубине более 220 метров. Она бережно фильтруется без термической обработки и смягчается посредством дополнительной ионной фильтрации, что придает ей особую мягкость',
        blending: 'смешивание',
        'Alcohol is mixed with water under high pressure and is cooled to minus 15 degrees for additional segmentation of possible impurities':
            'Спирт смешивается с водой под высоким давлением и охлаждается до минус 15 градусов для дополнительной сегментации возможных примесей',
        filtration: 'фильтрация',
        'Schmidt vodka is filtered without additional pressure through a charcoal filter made from maple, coconut palm and birch wood. Then vodka undergoes a unique filtration process through natural white pearls giving vodka its unique velvety smoothness':
            'Водка Schmidt фильтруется без дополнительного давления через угольный фильтр из древесины клена, кокосовой пальмы и березы. Затем водка проходит уникальный процесс фильтрации через натуральный белый жемчуг, придающий водке ее неповторимую бархатистую мягкость',
        'SCHMIDT SUPREME': 'SCHMIDT SUPREME',
        'Schmidt dry martini': 'Шмидт сухой мартини',
        "The highest quality ingredients, their delicate balance and perfect serving are the three components of this legendary cocktail, which in many ways has become an indicator of the bartender's skill":
            'Высочайшее качество ингредиентов, их тонкий баланс и идеальная подача — три составляющих этого легендарного коктейля, который во многом стал показателем мастерства бармена.',
        'Schmidt Supreme': 'Schmidt Supreme',
        'Dry vermouth': 'Сухой вермут',
        'Cool thoroughly and combine vermouth and vodka in a mixing glass, strain into a cocktail glass and top with an olive on a skewer':
            'Тщательно охладите и смешайте вермут и водку в смесительном стакане, процедите в коктейльный бокал и украсьте оливкой на шпажке.',
        'SCHMIDT WHITE RUSSIAN': 'ШМИДТ БЕЛЫЙ РУССКИЙ',
        'Schmidt White Russian is ideal premium classic. It has a creamy taste, the softness of which is emphasized by Schmidt vodka':
            'Schmidt White Russian — идеальная премиальная классика. Имеет сливочный вкус, мягкость которого подчеркивает водка Schmidt.',
        'Vanilla cream': 'Ванильный крем',
        'Coffee liqueur': 'Кофейный ликер',
        'Pour coffee liqueur and vodka into a rocks glass, stir and add cream. serving everything with a slice of chocolate for the perfect garnish':
            'Налейте кофейный ликер и водку в бокал рокс, перемешайте и добавьте сливки. Подавайте все с кусочком шоколада для идеального украшения.',
        'SCHMIDT GRASSHOPPER': 'SCHMIDT GRASSHOPPER',
        'Schmidt Grasshopper is a creamy, smooth, digestive cocktail with a pleasant, refreshing minty aftertaste. The ideal accompaniment for it would be bitter and rich chocolate':
            'Schmidt Grasshopper — это сливочный, гладкий, пищеварительный коктейль с приятным, освежающим мятным послевкусием. Идеальным дополнением к нему будет горький и насыщенный шоколад',
        Cream: 'Крем',
        'Mint liqueur': 'Мятный ликер',
        'Decorate the rim of a cocktail or sparkling glass with melted milk chocolate. After this, mix all the ingredients in a shaker with plenty of ice and carefully filter into a glass':
            'Украсьте край коктейльного или игристого бокала растопленным молочным шоколадом. После этого смешайте все ингредиенты в шейкере с большим количеством льда и осторожно процедите в бокал',
        'SCHMIDT GRAPEFRUIT': 'SCHMIDT GRAPEFRUIT',
        'SCHMIDT PALOMA': 'SCHMIDT PALOMA',
        'One of the most charismatic and atmospheric drinks in the whole world. A cocktail that will certainly create a bright mood':
            'Один из самых харизматичных и атмосферных напитков во всем мире. Коктейль, который обязательно создаст яркое настроение.',
        'Schmidt Grapefruit': 'Шмидт Грейпфрут',
        Campari: 'Кампари',
        'Grapefruit juice': 'Грейпфрутовый сок',
        'Lime juice': 'Сок лайма',
        Garnish: 'Украсить',
        paprika: 'паприка',
        'Mix Schmidt Grapefruit, fresh lime and grapefruit juices in a shaker with ice, add a little orange bitter and a pinch of salt, pour into a tall glass with ice and add a splash of soda. The edge of the glass can be decorated with salted or smoked paprika':
            'Смешать в шейкере со льдом Schmidt Grapefruit, свежий лаймовый и грейпфрутовый соки, добавить немного апельсинового биттера и щепотку соли, вылить в высокий стакан со льдом и добавить немного содовой. Край стакана можно украсить соленой или копченой паприкой',
        'SCHMIDT SMASH': 'SCHMIDT SMASH',
        'Always fresh, always summer and always modern. Scmidt Smash is an easy to make cocktail that is perfect for dinner or a party. Fresh basil, grapefruit vodka, juicy lemon and vanilla combine perfectly in an easy and popular mix':
            'Всегда свежий, всегда летний и всегда современный. Scmidt Smash — это простой в приготовлении коктейль, который идеально подходит для ужина или вечеринки. Свежий базилик, грейпфрутовая водка, сочный лимон и ваниль прекрасно сочетаются в простой и популярной смеси',
        'Lemon juice': 'лимонный сок',
        'Vanilla syrup': 'Ванильный сироп',
        Basil: 'Базилик',
        '12 medium leaves': '12 средних листьев',
        'egg white': 'яичный белок',
        Decoration: 'Украшение',
        grapefruit: 'грейпфрут',
        ICE: 'ЛЕД',
        'SCHMIDT CRANBERRY': 'SCHMIDT CRANBERRY',
        'SCHMIDT VODKA TONIC': 'ШМИДТ ВОДКА ТОНИК',
        'Schmidt Vodka Tonic is an impeccable mix with a clean, bittersweet, refreshing finish':
            'Водка-тоник Schmidt — безупречный микс с чистым, горько-сладким, освежающим послевкусием.',
        'Schmidt Cranberry': 'Шмидт Клюква',
        Tonic: 'Тоник',
        'currant sprig': 'веточка смородины',
        'Pour vodka and tonic into a tall glass with ice. Stir and garnish with a sprig of bright currants, frozen cranberries or a spike':
            'Налейте водку и тоник в высокий стакан со льдом. Размешайте и украсьте веточкой яркой смородины, замороженной клюквой или колосом.',
        'SCHMIDT CLUB': 'SCHMIDT CLUB',
        'A twist on the famous Clover Club cocktail. We prepare it with Schmidt Cranberry base and add the juice of thawed cranberries. The airy and light texture of this drink is perfect for the bright and juicy berry flavor of cranberries':
            'Изюминку на тему знаменитого коктейля Clover Club. Мы готовим его на основе Schmidt Cranberry и добавляем сок размороженной клюквы. Воздушная и легкая текстура этого напитка идеально подходит для яркого и сочного ягодного вкуса клюквы',
        'Cranberry juice': 'Клюквенный сок',
        'Sugar syrup': 'Сахарный сироп',
        'Egg White': 'Яичный белок',
        'Thaw and squeeze cranberries, add this juice and the rest of the ingredients to a shaker, mix vigorously and then filter into a cocktail glass. Garnish the drink with mint or sprinkle with cinnamon.':
            'Разморозьте и отожмите клюкву, добавьте этот сок и остальные ингредиенты в шейкер, энергично перемешайте и затем процедите в коктейльный бокал. Украсьте напиток мятой или посыпьте корицей.',
        'SCHMIDT Black Currant': 'SCHMIDT Черная смородина',
        'SCHMIDT BLACK CURRANT MOJITO': 'МОХИТО ИЗ ЧЕРНОЙ СМОРОДИНЫ SCHMIDT',
        'A variation of the famous refreshing cocktail, which harmoniously combines bright citrus notes, minty freshness and the sweetness of ripe currants':
            'Вариация известного освежающего коктейля, в которой гармонично сочетаются яркие цитрусовые ноты, мятная свежесть и сладость спелой смородины.',
        'Schmidt Black Currant': 'Шмидт Черная смородина',
        'Lime Juice': 'Сок лайма',
        mint: 'мята',
        '8 leaves': '8 листьев',
        Lime: 'Лайм',
        '4 wedges': '4 клина',
        'Blackcurrant jam': 'Варенье из черной смородины',
        '1 teaspoon': '1 чайная ложка',
        soda: 'газировка',
        'Invigorate the mint with an intense clap of your hands. Place mint, lime wedges and ice in a tall glass. Pour in the remaining ingredients and mix thoroughly. Garnish the drink with a sprig of mint and enjoy!':
            'Оживите мяту интенсивным хлопком в ладоши. Положите мяту, дольки лайма и лед в высокий стакан. Влейте оставшиеся ингредиенты и тщательно перемешайте. Украсьте напиток веточкой мяты и наслаждайтесь!',
        'SCHMIDT BLACK CLUB': 'SCHMIDT BLACK CLUB',
        'A juicy mix based on soft berry vodka, lime juice and honey syrup. The airy foam from thawed blackcurrant juice gives the drink a special delicacy, creating a light and so drinkable texture of the cocktail.':
            'Сочный микс на основе мягкой ягодной водки, сока лайма и медового сиропа. Воздушная пена из размороженного сока черной смородины придает напитку особую изысканность, создавая легкую и такую ​​питкую текстуру коктейля.',
        'honey syrup': 'медовый сироп',
        'Thawed blackcurrant juice': 'Размороженный сок черной смородины',
        'Thaw and squeeze blackcurrants, add this juice and the rest of the ingredients to a shaker, mix vigorously and then filter into a cocktail glass. Garnish the drink with mint or sprinkle with cinnamon.':
            'Разморозьте и отожмите черную смородину, добавьте этот сок и остальные ингредиенты в шейкер, энергично перемешайте, а затем процедите в коктейльный бокал. Украсьте напиток мятой или посыпьте корицей.',
        'ingredients': 'ингредиенты',
        'how to make': 'как приготовить',
        'ml': 'мл',
        'Remove 12 fresh green basil leaves from the stem, place them in a shaker and add the remaining ingredients and ice. Shake everything well, then filter into a glass with ice and garnish with a slice of grapefruit.': 'Снимите 12 свежих зеленых листьев базилика со стебля, поместите их в шейкер и добавьте оставшиеся ингредиенты и лед. Все хорошо встряхните, затем процедите в стакан со льдом и украсьте ломтиком грейпфрута.',
        'contact details': 'контактные данные',
        'SchmidtSpirits OÜ': 'SchmidtSpirits OÜ',
        '50-201, Vesivarava str, 10152 Tallinn, Estonia': 'ул. Весиварава, 50-201, 10152 Таллинн, Эстония',
        'phone': 'телефон',
        'email us': 'напишите нам',
        'name': 'имя',
        'email': 'email',
        'file, max 10mb': 'файл, макс. 10мб',
        'send': 'отправить',
        '© SchmidtSpiritsOÜ 2024': '© SchmidtSpiritsOÜ 2024',
        'privacy policy': 'политика конфиденциальности',
        'cookies policy': 'политика cookie',
        'message': 'сообщение',
    },
}
